import { Order, getNotificationRecipients } from '@vates/www-xo-utils'
import {
  AccountNotFound,
  AuthenticationFailed,
  ExpiredToken,
  HandledErrors,
  ResourceDeleted,
} from '@vates/xw-api-errors'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import { injectState, provideState } from 'reaclette'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, Route, HashRouter as Router, Switch } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Container } from 'reactstrap'
import UrlParse from 'url-parse'

import { generateCrossAuthToken, getApi, verifyCrossAuthToken } from '../api'
import * as Utils from '../utils'
import AccountManagement from './account-management'
import AccountRecovery from './account-recovery'
import ActivateAccount from './activate-account'
import AdditionalEmailRecipients from './additional-email-recipients'
import AuthenticationForm from './authentication-form'
import BillingInfo from './billing-info'
import ConfirmModal from './components/confirm-modal'
import Loader from './components/loader'
import CreditCardInfo from './credit-card-info'
import ErrorPage from './error-page'
import Footer from './footer'
import Header from './header'
import Invoices from './invoices'
import Menu from './menu'
import Partner from './partner'
import Purchases from './purchases'
import SignupForm from './sign-up'
import Support from './support'
import Unsubscribe from './unsubscribe'
// import PartnerPortal from './partner-portal'

function getParamsFromUrl() {
  const { cat, origin } = UrlParse(window.location.hash.slice(1), true).query
  const { pathname } = UrlParse(window.location.href, true)
  return {
    cat,
    origin,
    pathname,
  }
}

const PrivateRoute = injectState(
  ({ state, component: Component, ...props }) => (
    <Route
      render={(routeProps) =>
        state.logged ? (
          <Menu role={state.role}>
            <Component {...routeProps} {...props} />
          </Menu>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  )
)

const withState = provideState({
  initialState: () => ({
    account: undefined,
    authLoading: true,
    config: undefined,
    customerId: undefined,
    orders: [],
    ordersLoading: false,
    origin: 'account',
    submitLoading: false,
    modalAction: undefined,
    modalActionArgs: [],
    modalConfiguration: {},
    modalStatus: false,
    endUsersEmail: {},
  }),
  effects: {
    async initialize() {
      await this.effects.loadConfig()
      const { cat, origin } = getParamsFromUrl()
      await this.effects.authenticate(cat)
      if (origin) {
        this.state.origin = origin
      }
    },
    async loadConfig(effects) {
      try {
        const config = await getApi().getClientConf([
          'baseUrl',
          'companyUrl',
          'storeFrontEndUrl',
          'stripePublicKey',
          'supportFrontEndUrl',
          'partnerPortalFrontEndUrl',
          'xcpngUrl',
        ])
        this.state.config = config
      } catch (error) {
        await effects.handleError(error)
        window.location.replace('/#/error')
      }
    },
    async authenticate(effects, cat) {
      this.state.authLoading = true
      try {
        const jwt = cat || Cookies.get('accessToken')
        if (jwt) {
          const customerId = await verifyCrossAuthToken(decodeURIComponent(jwt))
          if (customerId) {
            await effects.signIn(customerId)
          }
        }
      } catch (error) {
        Cookies.remove('accessToken')
      }
      this.state.authLoading = false
    },
    async loadOrders() {
      try {
        this.state.ordersLoading = true
        const { orders } = await getApi(this.state.role).loadOrders(
          this.state.account.token
        )
        if (!isEmpty(orders.data)) {
          this.state.orders = Object.keys(orders.data)
            .map((order) => orders.data[order])
            .filter((order) => order.status !== Order.ORDER_STATUS.CANCELLED)
            .sort((a, b) => b.placingDate - a.placingDate)
        }
      } catch (error) {
        await this.effects.handleError(error)
      } finally {
        this.state.ordersLoading = false
      }
    },
    async handleError(effects, error) {
      if (error instanceof HandledErrors) {
        await effects.notify('error', error.message)
      } else {
        try {
          await getApi().criticalErrorFeedback(
            this.state.xwToken,
            Utils.serializeError(error),
            'ACCOUNT'
          )
          await effects.notify(
            'error',
            "Oops, something went wrong! Don't worry, our team is already on it"
          )
        } catch (error) {
          await effects.notify(
            'error',
            'Something went wrong! Contact us if the problem persists'
          )
        }
      }
    },
    logOut() {
      Cookies.remove('accessToken')
      this.state.account = undefined
      this.state.customerId = undefined
      this.state.orders = []
      window.location.replace('/#/')
    },
    async signIn(effects, customerId) {
      try {
        const account = await getApi().getAccount(customerId)
        effects.setAccessToken(customerId)
        this.state.account = account
        this.state.customerId = customerId
        await effects.loadOrders()
      } catch (error) {
        if (
          error instanceof AccountNotFound ||
          error instanceof ResourceDeleted
        ) {
          Cookies.remove('accessToken')
        } else {
          await effects.handleError(error)
        }
      }
    },
    async setAccessToken(_, customerId) {
      const DAYS = 30
      const eat = Date.now() + 3.6e6 * 24 * DAYS // 30 days
      const accessToken = await generateCrossAuthToken(customerId, eat)
      if (accessToken) {
        Cookies.set('accessToken', accessToken, { expires: DAYS })
      }
    },
    /**
     * @param {string} type: info, success, warning, error, default
     * @param {string} message
     * @param {number || boolean} autoClose
     */
    notify(_, type, messsage, autoClose = 10000) {
      toast[type](messsage, {
        position: 'top-center',
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    },
    async updateData(effects, account) {
      if (account) {
        this.state.account = account
      } else {
        try {
          this.state.account = await getApi().getAccount(this.state.customerId)
        } catch (error) {
          await effects.handleError(error)
        }
      }
    },
    async decodeJwt(effects, jwt) {
      try {
        await verifyCrossAuthToken(decodeURIComponent(jwt))
      } catch (error) {
        if (error instanceof ExpiredToken) {
          effects.notify('error', 'Token expired')
        } else if (error instanceof AuthenticationFailed) {
          effects.notify('error', 'Invalid token')
        } else {
          effects.notify('error', error.message)
        }
        window.location.replace('/#/login')
      }
    },
    confirm(effects, modalAction, modalActionArgs, modalConfiguration) {
      this.state.modalAction = modalAction
      this.state.modalActionArgs = modalActionArgs
      this.state.modalConfiguration = modalConfiguration
      this.state.modalStatus = true
    },
    closeModal() {
      this.state.modalStatus = false
      this.state.modalAction = undefined
      this.state.modalActionArgs = []
      this.state.modalConfiguration = {}
    },
  },
  computed: {
    billingInfo: ({ account }) => account && account.billingInfo,
    logged: ({ account }) => Boolean(account),
    role: ({ account }) =>
      account &&
      account.role &&
      account.role.reseller &&
      account.role.reseller.status === 'granted'
        ? 'reseller'
        : 'purchaser',
  },
})

const App = ({ effects, state }) => {
  if (state.authLoading) {
    return <Loader />
  }

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Helmet>
        <title>Account</title>
      </Helmet>
      <Header customerId={state.customerId} config={state.config} />
      <Container fluid>
        <Router>
          <Switch>
            <PrivateRoute
              path="/billingInfo"
              component={BillingInfo}
              billingInfo={state.billingInfo}
              customerId={state.customerId}
              updateData={effects.updateData}
              logOut={effects.logOut}
            />
            <PrivateRoute
              path="/purchases"
              component={Purchases}
              customerId={state.customerId}
              purchases={Utils.get(() => state.account.purchasing.purchases)}
              account={state.account}
              updateData={effects.updateData}
            />
            <PrivateRoute
              path="/invoices"
              component={Invoices}
              customerId={state.customerId}
            />
            <PrivateRoute
              path="/creditCard"
              component={CreditCardInfo}
              cardInfo={Utils.get(() => state.account.stripe.card)}
              stripePublicKey={Utils.get(() => state.config.stripePublicKey)}
              cardHolder={Utils.get(() => state.billingInfo.lastName)}
              customerId={state.customerId}
              updateData={effects.updateData}
            />
            <PrivateRoute
              path="/additionalEmailRecipients"
              component={AdditionalEmailRecipients}
              recipients={
                state.account ? getNotificationRecipients(state.account) : []
              }
              customerId={state.customerId}
              updateData={effects.updateData}
            />
            <PrivateRoute path="/partner" component={Partner} />
            {/* <PrivateRoute path="/partner-portal" component={PartnerPortal} /> */}
            <PrivateRoute path="/support" component={Support} />
            <PrivateRoute
              path="/account-management"
              component={AccountManagement}
              customerId={state.customerId}
              billingInfo={state.billingInfo}
              updateData={effects.updateData}
              preferences={Utils.get(() => state.account.preferences)}
              logOut={effects.logOut}
            />
            <Route
              path="/login"
              render={(props) => (
                <AuthenticationForm
                  loading={state.authLoading}
                  onSubmit={effects.signIn}
                  location={props.location.state}
                />
              )}
            />
            <Route
              path="/signup"
              render={() => <SignupForm loading={state.authLoading} />}
            />
            <Route
              path="/activation/:token(.*)"
              render={(props) => (
                <ActivateAccount {...props} decodeJwt={effects.decodeJwt} />
              )}
            />
            <Route
              path="/recovery/:token(.*)"
              render={(props) => (
                <AccountRecovery {...props} decodeJwt={effects.decodeJwt} />
              )}
            />
            <Route
              path="/unsubscribe/:token"
              render={(props) => <Unsubscribe {...props} />}
            />
            <Route path="/error" component={ErrorPage} />
            <Redirect from="*" to="/billingInfo" />
          </Switch>
        </Router>
        <ConfirmModal
          close={effects.closeModal}
          modalAction={state.modalAction}
          modalActionArgs={state.modalActionArgs}
          modalConfiguration={state.modalConfiguration}
          modalStatus={state.modalStatus}
        />
      </Container>
      <Footer />
    </Fragment>
  )
}

export default withState(injectState(App))
